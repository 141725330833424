"use client";
export {
	createAvatarScope,
	//
	Avatar,
	AvatarImage,
	AvatarFallback,
	//
	Root,
	Image,
	Fallback,
} from "./Avatar";
export type {
	AvatarProps,
	AvatarImageProps,
	AvatarFallbackProps,
} from "./Avatar";
