"use client";
export {
	createAccordionScope,
	//
	Accordion,
	AccordionItem,
	AccordionHeader,
	AccordionTrigger,
	AccordionContent,
	//
	Root,
	Item,
	Header,
	Trigger,
	Content,
} from "./Accordion";
export type {
	AccordionSingleProps,
	AccordionMultipleProps,
	AccordionItemProps,
	AccordionHeaderProps,
	AccordionTriggerProps,
	AccordionContentProps,
} from "./Accordion";
